import React from 'react'
import styled, { css } from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Headline = styled.h1`
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 2.8rem;
`

const SubHeadline = styled.h2`
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 2.2rem;
`

const Impressum = () => (
  <Layout>
    <SEO title="Impressum" />
    <div className="grid">
      <div className="grid-contained">
        <Headline>Impressum</Headline>
        <p>
          Konfliktrezept ist eine Kooperation als Einzelunternehmer tätiger
          Mediatorinnen.
        </p>
        <p>
          Dieser Webauftritt ist eine gemeinsame Werbemaßnahme von Franziska
          Schacht und{' '}
          <a
            href="http://ruthniessen.de"
            rel="noopener noreferrer"
            css={css`
              color: #3668ed;
            `}
          >
            Ruth Nießen
          </a>
          .
        </p>
        <SubHeadline>Angaben gem&auml;&szlig; &sect; 5 TMG</SubHeadline>
        <p>
          Ruth Nie&szlig;en
          <br /> Kochhannstr. 38
          <br /> 10249 Berlin
        </p>
        <br />
        <p>
          Franziska Schacht
          <br /> Schlo&szlig;str. 28
          <br /> 12163 Berlin
        </p>
        <SubHeadline>Kontakt</SubHeadline>
        <p>E-Mail: hallo@konfliktrezept.de</p>
        <SubHeadline>
          Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
        </SubHeadline>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <SubHeadline>Haftung f&uuml;r Inhalte</SubHeadline>
        <p>
          Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG
          f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen
          Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als
          Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder
          gespeicherte fremde Informationen zu &uuml;berwachen oder nach
          Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit
          hinweisen.
        </p>
        <p>
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon
          unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab
          dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
          m&ouml;glich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
          werden wir diese Inhalte umgehend entfernen.
        </p>
        <SubHeadline>Haftung f&uuml;r Links</SubHeadline>
        <p>
          Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf
          deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir
          f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen.
          F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige
          Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
          Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche
          Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige Inhalte
          waren zum Zeitpunkt der Verlinkung nicht erkennbar.
        </p>
        <p>
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
          ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </p>
        <SubHeadline>Urheberrecht</SubHeadline>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die
          Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der
          Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes
          bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
          Erstellers. Downloads und Kopien dieser Seite sind nur f&uuml;r den
          privaten, nicht kommerziellen Gebrauch gestattet.
        </p>
        <p>
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
        </p>
      </div>
    </div>
  </Layout>
)

export default Impressum
